export const AI_STREAMING_SETUP = 'ai:streaming:setup'
export const AI_STREAMING_MSG = 'ai:streaming:msg'
export const AI_STREAMING_RESPONSE = 'ai:streaming:response'
export const AI_STREAMING_END = 'ai:streaming:end'

export const AI_INSIGHT_SETUP = 'ai:insight:setup'
export const AI_INSIGHT_RESPONSE = 'ai:insight:response'
export const AI_INSIGHT_END = 'ai:insight:end'

export const UNARY = 'unary'
export const CLIENT_STREAMING = 'client_streaming'
export const SERVER_STREAMING = 'server_streaming'
export const BIDIRECTIONAL_STREAMING = 'bidirectional_streaming'
