/* eslint-disable @typescript-eslint/camelcase */
import {
  AI_INSIGHT_END,
  AI_INSIGHT_RESPONSE,
  AI_INSIGHT_SETUP,
  AI_STREAMING_END,
  AI_STREAMING_MSG,
  AI_STREAMING_RESPONSE,
  AI_STREAMING_SETUP,
  BIDIRECTIONAL_STREAMING,
  SERVER_STREAMING,
} from '../../constants/grpcConstants'

// TODO: get/set this object up from CMS's Grpc data
export const configObjects: { [key: string]: any } = {
  SpeechInsightOrchestrator: {
    type: BIDIRECTIONAL_STREAMING,
    streamMessages: [
      {
        title: 'streaming_config',
        data: {
          streaming_config: {
            config: {
              encoding: 'LINEAR16',
              language_code: 'en-US',
              sample_rate_hertz: 16000,
            },
          },
        },
      },
      {
        title: 'streaming_insight_config',
        data: {
          streaming_insight_config: {
            clientId: 'client-1',
            orgId: '',
            conversationId: '',
            role: 'CALLER',
            roleId: 'supervisor',
            ccaiConfigId: '',
            serviceConfigs: [
              {
                serviceType: 'TRANSCRIPTION',
              },
              {
                serviceType: 'AGENT_ANSWERS',
              },
            ],
          },
        },
      },
    ],
    setupMessageName: AI_STREAMING_SETUP,
    streamMessageName: AI_STREAMING_MSG,
    responseMessageName: AI_STREAMING_RESPONSE,
    endMessageName: AI_STREAMING_END,
  },
  AiInsight: {
    type: SERVER_STREAMING,
    methodArgument: {
      title: 'insightServingRequest',
      data: {
        insightServingRequest: {
          conversationId: '',
          orgId: '',
          realTimeTranscripts: true,
          historicalTranscripts: true,
          realtimeAgentAssist: true,
          historicalAgentAssist: true,
          realTimeSentiments: true,
          historicalSentiments: true,
        },
      },
    },
    setupMessageName: AI_INSIGHT_SETUP,
    responseMessageName: AI_INSIGHT_RESPONSE,
    endMessageName: AI_INSIGHT_END,
  },
}
