import { graphql } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import GrpcItem from '../../components/GrpcItem'
import GrpcServiceItem from '../../components/GrpcServiceItem'
import GrpcTryOut from '../../components/GrpcTryOut'
import TryOutAndSampleContainer from '../../components/TryOutAndSampleContainer'
import { useApiVersions } from '../../hooks/useApiVersions'
import {
  IGrpcDiscoveredItemSimpleInfo,
  IGrpcDiscoveries,
  IGrpcDocumentation,
  IGrpcEnum,
  IGrpcFile,
  IGrpcItemsDiscovered,
  IGrpcMessages,
  IGrpcService,
} from '../../interfaces/grpcInterfaces'
import ApiLayout from '../../layouts/ApiLayout'
import { configObjects } from './grpc-services-config'

export interface IGrpcDocumentationProps {
  data: {
    strapiGrpcs: {
      data: IGrpcDocumentation
    }
  }
  pageContext: {
    name: string
    resourceName: string
    version: number
  }
}

export const query = graphql`
  query GrpcApiQuery($name: String!, $version: Int!) {
    strapiGrpcs(name: { eq: $name }, version: { eq: $version }) {
      data
    }
  }
`

const GrpcDocumentation: React.FC<IGrpcDocumentationProps> = ({
  data,
  pageContext,
}): JSX.Element => {
  const grpc = data.strapiGrpcs
  const {
    name: apiName,
    version: apiCurrentVersion,
    resourceName,
  } = pageContext
  const { t } = useTranslation()
  const apiVersionNums = useApiVersions(apiName, 'grpcs')

  const getGrpcEnumItems = (): IGrpcDiscoveries => {
    const itemsDiscovered = [] as IGrpcDiscoveredItemSimpleInfo[]
    const jsxItems = grpc.data.files.map((protobufFile: IGrpcFile) => {
      return protobufFile.enums.map((enumeration: IGrpcEnum, i: number) => {
        itemsDiscovered.push({
          name: enumeration.name,
          longName: enumeration.longName,
          fullName: enumeration.fullName,
        })
        return (
          <GrpcItem
            key={i}
            id={enumeration.fullName}
            name={enumeration.longName}
            description={enumeration.description}
            fields={enumeration.values}
          />
        )
      })
    })

    return {
      jsxItems,
      itemsDiscovered: { itemType: 'enums', items: itemsDiscovered },
    }
  }

  const getGrpcServiceItems = (): IGrpcDiscoveries => {
    const itemsDiscovered = [] as IGrpcDiscoveredItemSimpleInfo[]
    const jsxItems = grpc.data.files.map((protobufFile: IGrpcFile) => {
      return protobufFile.services.map((service: IGrpcService, i) => {
        itemsDiscovered.push({
          name: service.name,
          longName: service.longName,
          fullName: service.fullName,
        })
        return <GrpcServiceItem key={i} service={service} />
      })
    })

    return {
      jsxItems,
      itemsDiscovered: { itemType: 'services', items: itemsDiscovered },
    }
  }

  const getGrpcMessageItems = (): IGrpcDiscoveries => {
    const itemsDiscovered = [] as IGrpcDiscoveredItemSimpleInfo[]
    const jsxItems = grpc.data.files.map(
      (protobufFile: IGrpcFile, idx: number) => {
        return protobufFile.messages.map(
          (message: IGrpcMessages, i: number) => {
            itemsDiscovered.push({
              name: message.name,
              longName: message.longName,
              fullName: message.fullName,
            })
            return (
              <GrpcItem
                isActive={idx < 1 && i < 2}
                key={i}
                id={message.fullName}
                name={message.name}
                description={message.description}
                fields={message.fields}
              />
            )
          }
        )
      }
    )

    return {
      jsxItems,
      itemsDiscovered: { itemType: 'messages', items: itemsDiscovered },
    }
  }

  let simpleDiscoveries = new Array<IGrpcItemsDiscovered>()

  const serviceDiscoveries = getGrpcServiceItems()
  simpleDiscoveries = simpleDiscoveries.concat(
    serviceDiscoveries.itemsDiscovered
  )

  const messageDiscoveries = getGrpcMessageItems()
  simpleDiscoveries = simpleDiscoveries.concat(
    messageDiscoveries.itemsDiscovered
  )

  const enumDiscoveries = getGrpcEnumItems()
  simpleDiscoveries = simpleDiscoveries.concat(enumDiscoveries.itemsDiscovered)

  const leftSideContents = (
    <>
      <section className="documentation-section">
        <h2 className="documentation-section-header" id="services">
          {t('grpcDocumentation.services')}
        </h2>
        {serviceDiscoveries.jsxItems}
      </section>
      <section className="documentation-section">
        <h2 className="documentation-section-header" id="messages">
          {t('grpcDocumentation.messages')}
        </h2>
        {messageDiscoveries.jsxItems}
      </section>
      <section className="documentation-section">
        <h2 className="documentation-section-header" id="enums">
          {t('grpcDocumentation.enums')}
        </h2>
        {enumDiscoveries.jsxItems}
      </section>
    </>
  )

  const getTabs = (): string[] | undefined => {
    return simpleDiscoveries
      .find(({ itemType }) => itemType === 'services')
      ?.items.map(({ name }) => name)
  }

  const getTabPanes = (): JSX.Element[] | undefined => {
    return simpleDiscoveries
      .find(({ itemType }) => itemType === 'services')
      ?.items.map(({ name }) => {
        return (
          <GrpcTryOut {...configObjects[name]} grpcName={name} key={name} />
        )
      })
  }

  const rightSideContents = (
    <TryOutAndSampleContainer
      tabHeadings={getTabs()!}
      tabPanes={getTabPanes()!}
    />
  )

  return (
    <ApiLayout
      apiName={apiName}
      resourceName={resourceName}
      description={grpc.data.description}
      leftSideContents={leftSideContents}
      rightSideContents={rightSideContents}
      versions={apiVersionNums}
      currentVersion={apiCurrentVersion}
    />
  )
}

export default GrpcDocumentation
