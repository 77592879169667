import io, { Socket } from 'socket.io-client'
import { isBrowser } from '../utils/general.utils'

const socket: Socket | null = isBrowser()
  ? io(`${process.env.GATSBY_BFF_BASE_URL}`, {
      withCredentials: true,
      transports: ['websocket'],
    })
  : null

const registeredStreams: string[] = []

socket?.on('disconnect', (reason) => {
  // Stop any running streams if we lose connection to BFF
  registeredStreams.forEach((stream) => {
    socket.listeners(stream).forEach((listener) => {
      listener({ code: 14, details: 'Connection dropped' })
    })
  })

  if (reason === 'io server disconnect') {
    // the disconnection was initiated by the server, need to reconnect manually
    socket.connect()
  }
  // else the socket will automatically try to reconnect
})

export const registerOpenStream = (messageName: string): void => {
  if (!registeredStreams.includes(messageName)) {
    registeredStreams.push(messageName)
  }
}

export const listenForMessage = (
  messageName: string,
  callback: (...args: any[]) => void
): void => {
  socket?.on(messageName, callback)
}

export const emitMessage = (...args: any[]): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  socket?.emit(...args)
}
